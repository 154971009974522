import React, { useState, useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, Configure, connectHits, Highlight, Hits } from 'react-instantsearch-dom';
import styled from "@emotion/styled";
import { css} from "@emotion/core";
import { Link } from "gatsby"
import Button from 'react-bootstrap/Button';
import { isMentorImageValid } from "../utils/mentor-image";
import 'bootstrap/dist/css/bootstrap.min.css';

const AlgoliaKey = require("../utils/algolia-credentials")
const searchClient = algoliasearch(
    AlgoliaKey.appId,
    AlgoliaKey.searchKey,
  )

const SecondSectionCard = styled(Button)`
  padding:0;
  width: 200px;
  // height:290px;
  color: ${props => props.theme.colors.gray_dark};
  background-color: ${props => props.theme.colors.white};
  border-color: ${props => props.theme.colors.gray_border};
  font: 16px/22px Roboto;
  transition: 0.25s ease-out;
  &:hover {
    color: ${props => props.theme.colors.gray_dark};
    background-color: ${props => props.theme.colors.white};
    border-color: ${props => props.theme.colors.gray_border};
    box-shadow: 0px 3px 5px ${props => props.theme.buttons.secondary_small_button_violet.hovered.shadow} !important;
    cursor: pointer;
  }
  &:focus, &.focus, &:active, &.active {
    color: ${props => props.theme.colors.gray_dark};
    background-color: ${props => props.theme.colors.white};
    border-color: ${props => props.theme.colors.gray_border};
    box-shadow: none;
    cursor: pointer;
  }
`
const SecondSectionCardBody = styled(Card.Body)`
  text-align:center;
  overflow: hidden;
  padding: 9px;
  @media(max-width:768px) {
    height:140px;
  }
  @media(min-width:768px) {
    height:140px;
    word-break: break-word;
  }
  @media(min-width:992px) {
    height:140px;
  }
`
const SecondSectionCardTitle = styled(Card.Title)`
  font: Bold 16px/22px Roboto;
  margin-bottom:10px;
`
const SecondSectionCardSubtitle = styled(Card.Subtitle)`
  font: 12px/16px Roboto;
`
const LadyBossHighlight = styled(Highlight)`
  .ais-Highlight-highlighted, .ais-Snippet-highlighted {
    background-color: ${props => props.theme.colors.accent_yellow};
  }
`

const CustomHits = connectHits(({ hits, name }) => {
  console.log("props.name:", name);
  const filteredHits = hits.filter((hit) => hit.name !== name);
  return (
    <Row className="overflow-auto flex-nowrap" css={css`margin-top:15px;margin-right:0px; margin-left:0px; height: 330px;`}>
      {filteredHits.map(hit => (
        <Col md="2" css={css`padding-left: 0px;
        @media(max-width:767px) {
            margin-right:0px; 
        }
        @media(min-width:767px) {
            margin-right:95px; 
        }
        @media(min-width:991px) {
            margin-right:55px; 
        }
        @media(min-width:1200px) {
            margin-right:25px; 
        }
        `}>
          {/* <Link
            to={"/the-ladybosses/" + hit.name.trim().replace(/ /gi, '-').toLowerCase()} 
            asModal
          > */}
          
          <Link to={"/the-ladybosses/" + hit.name.trim().replace(/ /gi, '-').toLowerCase()}>
            <SecondSectionCard variant="light">
            
              <Card.Img alt={"WomenWorks Ladyboss - " + hit.name} variant="top" 
                  src={isMentorImageValid(hit.photoLink) ? hit.photoLink : "/ladybosses/"+ hit.name.trim().replace(/ /gi, '-').toLowerCase()+".jpg"}
                  css={ css` ${isMentorImageValid(hit.photoLink)? `filter: grayscale(100%);` : ``} 
                  object-fit:cover;
                    // width:calc(200/1440*100vw);
                    // width: 100%;
                    // height: calc(150/1440*100vw);
                    width:200px;
                    height:150px;
                  `}
                  />
              
              <SecondSectionCardBody>
                <SecondSectionCardTitle>
                  <LadyBossHighlight attribute="name" hit={hit} />
                </SecondSectionCardTitle>
                <SecondSectionCardSubtitle>
                  <LadyBossHighlight attribute="title" hit={hit} />
                </SecondSectionCardSubtitle>
              </SecondSectionCardBody>
              
            </SecondSectionCard>
            </Link> 
  
          
        </Col>
      ))}
    </Row>
  );
});
    
  
  const LadybossHits = styled(CustomHits)`
    .ais-Hits-item {
      border: none;
      box-shadow: none;
    }
  `  


function HorizontalScrollCards(props) {
  const [scrollLeft, setScrollLeft] = useState(0);
  const cardContainerRef = React.createRef();

  // const scrollHandler = (scrollDirection) => {
  //   const cardContainer = cardContainerRef.current;
  //   const cardWidth = cardContainer.querySelector('.card').offsetWidth;
  //   const newScrollLeft =
  //     scrollDirection === 'next'
  //       ? scrollLeft + cardWidth
  //       : scrollLeft - cardWidth;

  //   cardContainer.scrollTo({
  //     left: newScrollLeft,
  //     behavior: 'smooth',
  //   });

  //   setScrollLeft(newScrollLeft);
  // };
  var filter = props.filter
  console.log("filter:", filter);

  return (
    // <InstantSearch searchClient={searchClient} indexName={index}>
    <InstantSearch searchClient={searchClient} indexName="Mentors">
      <Configure // Use the Configure widget to set default refinements
        filters={filter }// Set your "default refinements here
        hitsPerPage={10} // Adjust the number of hits per page as needed
      />
      <Container fluid css={css`padding: 0px;`}>
          <LadybossHits name={props.name}/>
        {/* <Row>
            <Col>
            <button onClick={() => scrollHandler('prev')}>&lt; Prev</button>
          </Col>
          <Col>
            <button onClick={() => scrollHandler('next')}>Next &gt;</button>
          </Col>
        </Row> */}
      </Container>
    </InstantSearch>
  );
}

// function HitCard({ hit }) {
//     return (
//       <Col key={hit.objectID} className="col-lg-4 col-md-6 col-12">
//         <Card style={{ width: '18rem' }}>
//           <Card.Body>
//             <Card.Title>{hit.title}</Card.Title>
//             <Card.Text>{hit.content}</Card.Text>
//           </Card.Body>
//         </Card>
//       </Col>
//     );
//   }



export default HorizontalScrollCards;